import React from "react"
import Layout from "../../components/member/Layout"
import Information from "../../components/member/Information/Information"

const Index = () => {
  const pageSlug = "member"
  const pageTitle = "Home"
  return (
    <div>
      <Layout pageSlug={pageSlug} pageTitle={pageTitle}>
        <Information />
      </Layout>
    </div>
  )
}

export default Index
