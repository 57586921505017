import React from "react"
// import { Link } from "gatsby"
import Typography from "@material-ui/core/Typography"

import styles from "./Information.module.scss"

export default () => {
  return (
    <div className={styles.root}>
      <Typography className={styles.lead}>
        箕澤屋の集い場、QULA(クラ)へようこそ。
        <br />
        <br />
        箕澤屋は、江戸末期に建てられた、ただの古民家です。
        <br />
        <br />
        ちょっと装いが魅力的。 <br />
        ただそれだけです。 <br /> <br />
        しかし、ただそれだけのためにひたすら残す方法を考えてます。 <br /> <br />
        せっかく残すために時間を使うのであれば、楽しみながら、理想を求めて動きたい。
        <br />
        遊びながら、人のエネルギーが生まれるきっかけの場になったらいいなと。
        <br />
        <br />
        イメージは、信頼できる人たちが集まって、やりたいことをやりながら、その場所や人のつながりをつくるコミュニティガーデンみたいな場所。
        <br /> <br />
        と言ってもまだまだこれからです。少しずつできることをやってみようと、実験的なメンバーサイトをつくりました。{" "}
        <br /> <br />
        代表兄弟は、毎日コンピュータと戯れるディベロッパーたちです。 <br />
        こんなサイトならいいなといったリクエスト等、ご意見あればいつでもお待ちしております。
        <br />
        （あ、でも空き時間で動いてるので開発スピードは遅いです。ご理解いただければと。）
        <br />
        <br />
        ポジティブに。人に誠実に。
        <br /> <br />
        プロジェクト代表 <br />
        アヤナ
      </Typography>

      {/* <Typography variant="h5" gutterBottom>
        クラとは
      </Typography>
      <Typography className={styles.box}>
        クラは、箕澤屋でつながったメンバーの情報共有の場です。
      </Typography>
      <Typography variant="h5" gutterBottom>
        箕澤屋が目指していること
      </Typography>
      <Typography className={styles.box}>
        箕澤屋は、歴史ある立派なつくりと非日常の空間が魅力だと思ってます。そんな非日常の空間を、大人の遊び場として活用する。そうすることで、この場所の保存につながる仕組みができたらいいなと考えています。
        <br />
        現在はテスト運用中ですが、このプラットフォームを通して、箕澤屋メンバーだけが遊び場として施設を利用できる仕組みを構築し、将来的に有料化をすることで、維持していくためのコミュニティを形成していくことを目指しています。
        <br />
        <br />
        遊び場としての利用例：
        <br />
        長野の釣り拠点、春〜秋のBBQ、囲炉裏を使用した大人数での集まり、施設内でのキャンプ、サイクリングツアーの発着所
      </Typography>
      <Typography variant="h5" gutterBottom>
        クラでできること/できるようにしたいこと
      </Typography>
      <Typography className={styles.box}>
        ・箕澤屋の活動情報/イベント情報を知る
        <br />
        ・施設の利用予約をする
        <br />
        ・箕澤屋グッズを購入する
        <br />
        ※他にもメンバーが増えることでリクエストがあれば、その都度、機能追加を検討していきたいと考えています
      </Typography> */}
    </div>
  )
}
